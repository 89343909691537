<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    viewBox="0 0 10 11"
  >
    <path
      fill="currentColor"
      fill-rule="nonzero"
      d="M9.085 4.295H5.761V.716C5.761.321 5.464 0 5.097 0h-.444c-.367 0-.664.321-.664.716v3.579H.665c-.367 0-.665.321-.665.716v.478c0 .395.298.716.665.716h3.324v3.579c0 .395.297.716.664.716h.444c.367 0 .664-.321.664-.716V6.205h3.324c.367 0 .665-.321.665-.716v-.478c0-.395-.298-.716-.665-.716Z"
    />
  </svg>
</template>

<script>
  export default {
    name: 'PlusIcon'
  }
</script>
