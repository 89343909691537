<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    viewBox="0 0 11 2"
  >
    <path
      fill="currentColor"
      d="M9.784 0H.716C.321 0 0 .321 0 .716v.477c0 .395.321.716.716.716h9.068c.395 0 .716-.321.716-.716V.716C10.5.321 10.179 0 9.784 0Z"
    />
  </svg>
</template>

<script>
  export default {
    name: 'MinusIcon'
  }
</script>
